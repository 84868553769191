import { useContext } from "react";
import { useViewport } from "@headwaters-economics/web-shared/contexts/Viewport";
import appContext from "../context/appContext";
// import { useViewport } from "@headwaters-economics/web-shared/contexts/Viewport";
import LocationPicker from "@headwaters-economics/web-shared/components/LocationPicker";
import StateFilter from "./StateFilter";

import blmData from "../assets/data/blmData.json";

import { Box, Button, Grid, Select } from "grommet";
import { CaretUpFill } from "grommet-icons";
import { find } from "lodash";

const searchTypes = [
  { label: "BLM Field Office", value: "blm-office" },
  { label: "BLM National Conservation Land", value: "blm-nlcs" },
  { label: "County", value: "he-county" },
  { label: "State", value: "he-state" },
];

const Search = ({ onSelect }) => {
  const { screenSize, appWidth } = useViewport();
  const { stateFilter, searchType, setSearchType } = useContext(appContext);
  
  const Input = ({ onSelect }) => {
    return (
      <LocationPicker
        geoTypes={[searchType]}
        table={searchType.indexOf("blm") === 0 ? "he.geos_blm" : null}
        geoFilterType="he-state"
        geoFilterId={!stateFilter || stateFilter.value === "" ? null : stateFilter.value}
        placeholder={
          "Which " + find(searchTypes, { value: searchType }).label
          +
          (!stateFilter || stateFilter.value === "" ? "?" : " in " + stateFilter.label + "?")
        }
        onSelection={(item) => {
          if (onSelect) onSelect();
          // setSearchFeature(item);
        }}
        updateSuggestions={(suggestions) => {
          if (searchType.indexOf("blm") === 0) {
            return suggestions.map((item) => {
              console.debug(item)
              if (item && item.id && blmData[item.id]){
                item.description = '('+blmData[item.id].geos.map(geo=>geo.name).join(', ')+')'
              }
              return item;
            });
          }
          return suggestions;
        }}
        // clearOnClick={true}
        // clearOnSelect={true}

        width="auto"
        round="xsmall"
        // xtraFlds={searchType.indexOf("blm") === 0 ? ["vintage_blm"] : null}
      />
    );
  };

  const SearchTypeSwitch = () => {
    if (screenSize === "mobile") return null;

    if (appWidth < 1100) {
      return (
        <Grid columns={["100px", "auto"]}>
          <Box>Search by:</Box>
          <Box background={"light-3"} round="xsmall">
            <Select
              options={searchTypes}
              value={searchType}
              labelKey="label"
              valueKey={{ key: "value", reduce: true }}
              // onChange={({ option }) => setSearchType(option)}
              onChange={({ value: nextValue }) => setSearchType(nextValue)}
            />
          </Box>
        </Grid>
      );
    }

    const SearchTypeButton = ({ type, label }) => {
      return (
        <Box>
          <Button
            plain
            onClick={() => {
              setSearchType(type);
            }}
          >
            <Box
              background={type === searchType ? "blue" : "grey"}
              border
              align="center"
            >
              {label}
            </Box>
          </Button>
          {type === searchType && (
            <Box alignSelf="center" margin={{ top: "-18px", bottom: "-30px" }}>
              <CaretUpFill size="large" color="white" />
            </Box>
          )}
        </Box>
      );
    };

    return (
      <Grid columns={["25%", "43%", "16%", "16%"]} fill="horizontal">
        <SearchTypeButton type="blm-office" label="BLM Field Office" />
        <SearchTypeButton
          type="blm-nlcs"
          label="BLM National Conservation Land"
        />
        <SearchTypeButton type="he-county" label="County" />
        <SearchTypeButton type="he-state" label="State" />
      </Grid>
    );
  };

  if (screenSize === "mobile") {
    return <Input onSelect={onSelect} />;
  }
  return (
    <Box direction="column" gap="small">
      <Grid columns={["auto", "small"]} fill="horizontal" gap="small">
        <SearchTypeSwitch />
        <Box></Box>
        <Input onSelect={onSelect} />
        <StateFilter />
      </Grid>
    </Box>
  );
};

export default Search;
