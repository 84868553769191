import React from "react";
// import Layout from "./components/Layout";
import { Grommet, Box } from "grommet";
import { deepMerge } from "grommet/utils";
import GoogleAnalytics from "@headwaters-economics/web-shared/components/GoogleAnalytics";
import HeMainTheme from "@headwaters-economics/web-shared/themes/heMain";
// import StartScreen from "./screens/StartScreen";
import AppProvider from "./context/AppProvider";
import { ViewportProvider } from "@headwaters-economics/web-shared/contexts/Viewport";
import "./App.scss";
// import PrimaryScreen from "./screens/PrimaryScreen";
// import Main from "./components/Main";
import StartScreen from "./screens/StartScreen";


function App({ storybookState }) {
  return (
    <AppProvider storybookState={storybookState}>
      <ViewportProvider>
        {/* <Layout /> */}
        <Grommet full theme={HeMainTheme} className="app">
          <GoogleAnalytics gaAccount={"headwaterseconomics"} />

          <Box fill>
            <StartScreen />
            {/* <Main
              screenSize={screenSize}
              appHeight={appHeight}
              appWidth={appWidth}
            /> */}
            {/* Testing */}
            {/* <StartScreen />
            <PrimaryScreen /> */}
          </Box>
        </Grommet>
      </ViewportProvider>
    </AppProvider>
  );
}

export default App;
