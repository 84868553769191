import React, { useEffect, useReducer } from "react";

import AppContext from "./appContext";
import actions from "./actions";

import blmData from "../assets/data/blmData.json";
import useExtentOfGeos from "@headwaters-economics/web-shared/hooks/useExtentOfGeos";

// import forEach from "lodash/forEach";
// import sortBy from "lodash/sortBy";
// import indexOf from "lodash/indexOf";
import some from "lodash/some";
import filter from "lodash/filter";
// import join from "lodash/join";

// const getSearchData = (stateFilter, searchType) => {
//   if (searchType === "blm-office" || searchType === "blm-nlcs") {
//     let searchData = [];
//     forEach(blmData, (v, k) => {
//       if (
//         v.geoLevel === searchType &&
//         (!stateFilter ||
//           stateFilter.value === "" ||
//           indexOf(v.states, stateFilter.value) > -1)
//       ) {
//         let geoNames = v.geos.map((geo) => geo.name);
//         geoNames.sort();
//         let desc = join(geoNames, ", ");
//         searchData.push({
//           id: k,
//           label: v.name,
//           desc: desc,
//           geo_level: searchType,
//         });
//       }
//     });
//     return { data: sortBy(searchData, "label") };
//   } else {
//     return {
//       geoTypes: searchType,
//       geoFilterId: stateFilter ? stateFilter.value : null,
//     };
//   }
// };

const makeGeo = (id, name, geoLevel) => {
  return {
    id: id,
    label: name,
    name: name,
    geoLevel: geoLevel.indexOf("he-") === 0 ? geoLevel : "he-" + geoLevel,
  };
};

const initialState = {
  isStartScreenVisible: true,
  isSearchFocusedOnce: false,
  mapExtent: {
    minLat: 24,
    minLon: -133,
    maxLat: 50,
    maxLon: -64,
  },
  stateFilter: null,
  // searchData: getSearchData(null, "blm-office"),
  searchType: "blm-office",
  selectedGeos: [],
  searchFeature: null,
  popupContent: null,
  hoveredFeatureProps: null,
  highlightedGeo: null,
  selectionLayer: "he-state",
  benchmarkGeo: { id: "0", label: "United States" },
  refVis_blmOffices: false,
  refVis_blmNlcs: false,
  newExtentGeos: null,
};

const AppProvider = ({ children, storybookState }) => {
  const [state, dispatch] = useReducer(
    appReducer,
    storybookState ? { ...initialState, ...storybookState } : initialState
  );
  const { extentOfGeos: searchFeatureExtent } = useExtentOfGeos({
    geoIDs: state.newExtentGeos,
  });

  useEffect(() => {
    if (searchFeatureExtent)
      dispatch({ type: actions.SET_MAP_EXTENT, to: searchFeatureExtent });
  }, [searchFeatureExtent]);

  const setSearchType = (newType)=>{
    dispatch({type: actions.SET_SEARCH_TYPE_TO,to: newType});
  }

  const setStateFilter = (newState)=>{
    dispatch({type: actions.SET_STATE_FILTER_TO,to: newState});
  }

  const setSearchFeature = (newFeature)=>{
    dispatch({type:actions.SET_SEARCH_FEATURE_TO, to:newFeature})
  }

  return (
    <AppContext.Provider
      value={{
        isStartScreenVisible: state.isStartScreenVisible,
        searchType: state.searchType,
        setSearchType: setSearchType,
        stateFilter: state.stateFilter,
        setStateFilter: setStateFilter,
        searchFeature: state.searchFeature,
        setSearchFeature: setSearchFeature
        // dispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const appReducer = (state, action) => {
  let selectedGeos;
  let selectionLayer;
  switch (action.type) {
    case actions.SET_SEARCH_FEATURE_TO:
      selectionLayer = "he-county";

      if (
        action.to.geo_level === "blm-office" ||
        action.to.geo_level === "blm-nlcs"
      ) {
        selectedGeos = blmData[action.to.id].geos.map((geo) => {
          if (geo.geo_level === "state") {
            selectionLayer = "he-state";
          }
          return makeGeo(geo.id, geo.name, geo.geo_level);
        });
      } else {
        selectedGeos = [
          makeGeo(action.to.id, action.to.label, action.to.geo_level),
        ];
      }
      return {
        ...state,
        isStartScreenVisible: false,
        searchFeature: action.to,
        selectedGeos,
        selectionLayer,
        newExtentGeos: selectedGeos.map((geo) => geo.id),
      };
    case actions.SET_STATE_FILTER_TO:
      return {
        ...state,
        stateFilter: action.to,
        // searchData: getSearchData(action.to, state.searchType),
      };
    case actions.REMOVE_GEO:
      selectedGeos = filter(state.selectedGeos, (geo) => geo.id !== action.id);

      return {
        ...state,
        selectedGeos: selectedGeos,
      };
    case actions.ADD_GEO:
      //check to make sure the
      if (some(state.selectedGeos, { id: action.id })) return { ...state };
      selectedGeos = state.selectedGeos.concat(
        makeGeo(action.id, action.name, state.selectionLayer)
      );
      return {
        ...state,
        selectedGeos: selectedGeos,
      };
    case actions.CLEAR_GEOS:
      selectedGeos = [];

      return {
        ...state,
        selectedGeos: selectedGeos,
      };
    case actions.SET_POPUP_CONTENT:
      return {
        ...state,
        popupContent: action.content,
      };
    case actions.SET_HOVERED_FEATURE_PROPS:
      return {
        ...state,
        hoveredFeatureProps: action.hoveredFeatureProps,
      };

    case actions.SET_BENCHMARK_TO:
      return {
        ...state,
        benchmarkGeo: action.to,
      };
    case actions.SET_SEARCH_TYPE_TO:
      return {
        ...state,
        searchType: action.to,
        // searchData: getSearchData(state.stateFilter, action.to),
        stateFilter:
          action.to === "he-state"
            ? initialState.stateFilter
            : state.stateFilter,
      };
    case actions.SET_MAP_EXTENT:
      return {
        ...state,
        mapExtent: action.to,
      };
    case actions.SET_SELECTION_LAYER_TO:
      return {
        ...state,
        selectionLayer: action.to,
      };
    case actions.SET_REFVIS_BLM_OFFICES:
      return {
        ...state,
        refVis_blmOffices: !state.refVis_blmOffices,
      };
    case actions.SET_REFVIS_BLM_NLCS:
      return {
        ...state,
        refVis_blmNlcs: !state.refVis_blmNlcs,
      };
    case actions.SET_SEARCH_FOCUSED:
      return {
        ...state,
        isSearchFocusedOnce: true,
      };
    case actions.RETURN_TO_START:
      return {
        ...state,
        isStartScreenVisible: true,
        isSearchFocusedOnce: false,
      };
    default:
      throw new Error();
  }
};

export default AppProvider;
